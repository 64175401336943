label {
  align-items: center;
  padding: 12px;
  background: rgb(194, 0, 0);
  border-radius: 20px;
  display: table;
  color: #fff;
  /* margin-left: 700px; */
  /* padding-inline: 40px; */
}

.buttonUpload {
  background-color: #4caf50; /* Green */
  border: none;
  border-radius: 20px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
}
.ref {
  margin-top: auto;
}

input[type="file"] {
  display: none;
}

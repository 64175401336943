@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap);
/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #092b22;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(67, 131, 65);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7fc7f7;
  border-radius: 12px;
}

body {
  background-color: #222222;
  font-size: calc(10px + 2vmin);
  color: rgb(197, 219, 195);
  font-family: "Comfortaa", cursive;
}
h1 {
  font-family: "Comfortaa", cursive;
  font-weight: 700;
}
.nav,
a:link {
  color: rgb(197, 219, 195);
  text-decoration: none;
  padding: 10px;
  text-transform: uppercase;
}
.nav,
a:hover {
  color: rgb(42, 155, 38) !important;
  /* text-shadow: rgb(255, 255, 255) 0px 0px 5px; */
}
.nav,
a:visited {
  color: rgb(197, 219, 195);
  text-decoration: none;
}
.nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-color: rgb(255, 255, 255);
  height: 50px;
  position: fixed;
  width: 100%;
  background-color: #092b22da;
  z-index: 10;
  border-bottom: solid 2px;
  border-color: rgba(42, 167, 31, 0.459);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.34);
}
.header {
  font-size: 20px;
}
.logo {
  width: 400px;
  margin-left: 260px;
  margin-top: 100px;
}
.banniere {
  background-image: url(/static/media/bgHeader.e0c273af.webp);
  /* background-position: 0px -10px; */
  background-position: center;
  background-size: cover;
  height: 450px;
  border-bottom: solid 4px;
  border-color: rgba(42, 167, 31, 0.459);
}
.contact {
  display: flex;
  justify-content: center;
  text-align: center;
  display: flex;
  justify-content: space-around;
  margin-top: 28px;
}
.tel {
  color: rgb(255, 255, 255);
}
.container {
  border-top: solid 4px;
  border-bottom: solid 4px;
  border-color: rgba(42, 167, 31, 0.212);
  padding: 15px;
  padding-bottom: 30px;
  background-color: #092b2291;
  color: rgb(221, 238, 215);
  font-size: calc(5px + 2vmin);
  text-align: justify;
  margin-bottom: 30px;
}
.container2 {
  padding: 10px;
  padding-bottom: 30px;
  font-size: calc(5px + 2vmin);
  text-align: justify;
  margin-bottom: 30px;
}

/* Affichage des horaires -------------------------------------- */

.hoursWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.hoursBox {
  background: #092b22;
  color: rgb(221, 238, 215);
  border-radius: 10px;
}
.hoursContent {
  padding: 20px 30px;
}
.hours {
  display: flex;
  justify-content: space-between;
}
.hours ul {
  margin: 5px;
  padding: 10px;
  list-style: none;
}
.hours ul:first-child {
  font-weight: 700;
}
.hours ul:last-child {
  font-weight: 100;
}
.hours ul li {
  padding-bottom: 3px;
}

/* ---------------------------- Fin affichage des horaires */

.reservation {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.iframeReservation {
  border: 1px solid black;
  border-radius: 10px;
  height: 800px !important;
}
.photoContent {
  width: 100%;
  border-radius: 20px;
  border: solid 1px;
  border-color: rgb(140, 160, 138);
  box-shadow: black 0px 0px 10px;
}

/* ADMIN */

.adminCartes {
  display: flex;
  justify-content: space-around;
}
footer {
  text-align: center;
  margin-top: 30px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 2px solid rgba(42, 167, 31, 0.212);
  padding-top: 10px;
  background-color: #092b22;
  color: rgb(221, 238, 215);
}

/* CARTE */

.selectedCarte {
  margin: 20px;
}
.cardCarte {
  background: #286430;
  color: rgb(221, 238, 215);
  border-radius: 10px;
  text-align: center;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardCarte:hover {
  cursor: pointer;
  background: #499453;
}

.pages-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btnSwipPageMobile {
  display: none !important;
}
.btnSwipPageDesktop {
  display: block !important;
}
/* page contact */
.iframeMap {
  width: 100%;
  height: 300px;
}
.coordonnées {
  text-align: center;
}
/* page admin */
.signIn {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
}
.gallery {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 40px;
}
.linkArm,
.linkArm:hover {
  color: #ddedd7 !important;
  font-size: 13px;
}
/* MODULE AVIS GOOGLE =============================== */
.Avis1 {
  color: rgb(221, 238, 215);
  margin-top: 20px;
  font-size: 22px;
  margin-top: 50px;
}
.Avis2 {
  background-color: #1c221c;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  display: none;
}
.AvisStars {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.CardsAvis {
  background-color: #0e5230;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  font-size: 16px;
  width: 340px;
  height: 340px;
  margin: 5px;
}
.ContainerIdAvis {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ContainerIdName {
  margin-left: 20px;
}
.CommentaireAvis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* set here max line number you need */
  -webkit-line-clamp: 5;
  overflow: hidden;
}
/* style de react-elastic-carousel */
.rec-arrow:hover {
  background-color: rgb(61, 61, 61) !important;
}
.rec-arrow {
  background-color: rgb(215, 226, 207) !important;
}
.rec-dot_active {
  background-color: rgb(201, 201, 201) !important;
  box-shadow: 0 0 1px 3px rgb(46, 83, 37) !important;
}
.rec-dot:hover {
  box-shadow: 0 0 1px 3px rgb(103, 184, 83) !important;
}

/* =============================== MODULE AVIS GOOGLE*/

@media screen and (max-width: 1050px) {
  .logo {
    width: 200px;
    margin-left: 20px;
    margin-top: 90px;
  }
  .banniere {
    background-position: -600px 0px;
  }
  .nav div {
    display: none;
  }
  .contact {
    flex-direction: column;
  }
  .btnReseaux {
    margin-bottom: 20px;
    margin-top: -10px;
  }
  .tel {
    margin-top: -10px;
    font-size: 40px;
    margin-bottom: 10px;
  }
  .content {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    text-align: center;
  }
  .iframeReservation {
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    height: 400px;
  }
  .btnSwipPageDesktop {
    display: none !important;
  }
  .btnSwipPageMobile {
    display: block !important;
    margin-bottom: 10px !important;
  }
  .pageCarteResponsive {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .container,
  .container2 {
    font-size: calc(5px + 3vmin);
  }
}

/* POUR L'affichage des horaires en responsive.. */

@media screen and (max-width: 600px) {
  .hoursContent > .hours > ul   {
    font-size: 11px;
  }
}
.ResponsiveMenu {
  padding: 0px 10px;
  display: flex;
  background: #092b22;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  height: 60px;
  position: fixed;
  z-index: 12;
  width: 100%;
  /* margin-top: 50px; */
  box-shadow: 0px 0px 11px rgb(0, 0, 0);
}
/* .logo {
    width: 45px;
    height: 45px;
  } */
.nav-options {
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 80px;
  left: 100%;
  opacity: 0;
  /* transition: all 0.5s ease; */
  flex-direction: column;
  list-style-type: none;
  grid-gap: 0px;
}
.nav-options.active {
  background: #092b22;
  left: 0;
  opacity: 1;
  z-index: 1;
  align-content: center;
  padding-left: 0px;
  margin-top: -20px;
  padding-top: 20px;
}

.menu-icon {
  width: 40px;
  height: 40px;
}
.option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vw;
  padding: 30px 0px;
}
.sign-up {
  background: #2f743a;
  border-radius: 3px;
  padding: 20px 0px;
  width: 80%;
  align-self: center;
  border-radius: 3px;
  text-align: center;
  color: #eaeff3 !important;
}

@media screen and (min-width: 1050px) {
  .ResponsiveMenu {
    display: none !important;
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #216d2b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

label {
  align-items: center;
  padding: 12px;
  background: rgb(194, 0, 0);
  border-radius: 20px;
  display: table;
  color: #fff;
  /* margin-left: 700px; */
  /* padding-inline: 40px; */
}

.buttonUpload {
  background-color: #4caf50; /* Green */
  border: none;
  border-radius: 20px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
}
.ref {
  margin-top: auto;
}

input[type="file"] {
  display: none;
}


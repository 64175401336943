.ResponsiveMenu {
  padding: 0px 10px;
  display: flex;
  background: #092b22;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  height: 60px;
  position: fixed;
  z-index: 12;
  width: 100%;
  /* margin-top: 50px; */
  box-shadow: 0px 0px 11px rgb(0, 0, 0);
}
/* .logo {
    width: 45px;
    height: 45px;
  } */
.nav-options {
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 80px;
  left: 100%;
  opacity: 0;
  /* transition: all 0.5s ease; */
  flex-direction: column;
  list-style-type: none;
  grid-gap: 0px;
}
.nav-options.active {
  background: #092b22;
  left: 0;
  opacity: 1;
  z-index: 1;
  align-content: center;
  padding-left: 0px;
  margin-top: -20px;
  padding-top: 20px;
}

.menu-icon {
  width: 40px;
  height: 40px;
}
.option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vw;
  padding: 30px 0px;
}
.sign-up {
  background: #2f743a;
  border-radius: 3px;
  padding: 20px 0px;
  width: 80%;
  align-self: center;
  border-radius: 3px;
  text-align: center;
  color: #eaeff3 !important;
}

@media screen and (min-width: 1050px) {
  .ResponsiveMenu {
    display: none !important;
  }
}
